import * as fb from '@/api/firebase.js';
import axios from 'axios'

const actions = {
    contactUs(context, credentials) {
        return new Promise((resolve, reject) => {
            fb.contactusCollection.doc().set({
                name: credentials.name,
                email: credentials.email,
                phone: credentials.phone,
                message: credentials.message,
                page: credentials.page
            })
            .then(() => {
                resolve()
            })
            .catch((error) => {
                reject(error)
            })
        })
    },
    callToAction(context, credentials) {
        return new Promise((resolve, reject) => {
            let formData = {
                name: credentials.name,
                email: credentials.email,
                message: credentials.message
            }

            var callToActionRequest = fb.functions.httpsCallable("enterpriseContactUs");
            callToActionRequest(formData)
            resolve()
        })
    },
    newsletterConsumer(context, credentials) {
        return new Promise((resolve, reject) => {
            let email = credentials.email
            let formData = {
                email: credentials.email,
                type: "consumer"
            }

            var callToActionRequest = fb.functions.httpsCallable("newsletterSignup");
            callToActionRequest(formData)
            resolve()
        })
    },
    newsletterBusiness(context, credentials) {
        return new Promise((resolve, reject) => {
            let email = credentials.email
            let formData = {
                email: credentials.email,
                type: "business"
            }

            var callToActionRequest = fb.functions.httpsCallable("newsletterSignup");
            callToActionRequest(formData)
            resolve()
        })
    }
}

export default {
    actions
}