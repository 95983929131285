<template>
  <footer class="theme-footer-seven mt-80 md-mt-100">
    <div class="lg-container">
      <div class="container">
        <div class="row">
          <div class="col-xl-3 col-lg-2 mb-0" data-aos="fade-up" data-aos-duration="1200">
            <div class="logo"> <router-link to="/" :key="$route.fullPath"><img data-name="Ingomu-(@ingomu)" src="https://dktoyr513tjgs.cloudfront.net/v2/images/enterprise/Ingomu-(@ingomu).png" alt=""></router-link></div>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="bottom-footer">
          <div class="row">
            <div class="col-lg-4 order-lg-1 mb-20">
              <ul class="d-flex justify-content-center justify-content-lg-start footer-nav">
                <li><router-link to="/">Home</router-link></li>
                <li><router-link :to="{ name: 'blogcategory', params: { category: 'careers' } }">Careers</router-link>
                </li>
                <li><router-link to="/terms">Terms</router-link></li>
                <li><a href="#" data-toggle="modal" data-target="#contactModal">Contact</a></li>
              </ul>
            </div>
            <div class="col-lg-4 order-lg-3 mb-20">
              <ul class="d-flex justify-content-center justify-content-lg-end social-icon">
                <li>
                  <a href="https://www.facebook.com/ingomu.coaching/" target="_blank">
                    <i class="fab fa-facebook-square"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.youtube.com/@ingomu" target="_blank">
                    <i class="fab fa-youtube"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/ingomu.coaching/" target="_blank">
                    <i class="fab fa-instagram"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/company/ingomu" target="_blank">
                    <i class="fab fa-linkedin"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.pinterest.com/ingomu_coaching/" target="_blank">
                    <i class="fab fa-pinterest"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.tiktok.com/@ingomu" target="_blank">
                    <img class="h15" src="../assets/image/svg/tiktok.svg" />
                    <!-- <i class="fab fa-tiktok"></i> -->
                  </a>
                </li>
              </ul>
            </div>
            <div class="col-lg-4 order-lg-2 mb-20">
              <p class="copyright text-center">Copyright @{{ currentDate.getFullYear() }} Ingomu Learning, LLC</p>
            </div>
          </div>
        </div>
      </div>
    </div> <!-- /.lg-container -->
    <Cookies></Cookies>
  </footer> <!-- /.theme-footer-seven -->
</template>

<script>
import Cookies from "@/components/Cookies";

export default {
  name: 'site_footer',
  data() {
    return {
      currentDate: new Date
    }
  },
  components: {
		Cookies
	}
}
</script>